import './styles.scss';
import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import { Button, Input, Space, Alert } from 'antd';
import { RobotOutlined, LoadingOutlined, UserOutlined } from '@ant-design/icons';
import OpenAI from 'openai';
import { toast } from 'react-toastify';

// zustand 스토어
import { useSSEStore } from '../../store/sseStore';

interface Message {
    role: string;
    content: string;
    isResult?: boolean;
}

interface DataType {
    id: number;
    lastQuery: string;
    message: Message[];
}

function ModelAiTalk({ taskInstance, closeDialog }: { taskInstance: any; closeDialog?: any }): JSX.Element {
    console.log('taskInstance : ', taskInstance);

    const [messages, setMessages] = useState<DataType[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [chatInput, setChatInput] = useState<string>('');
    const [currentId, setCurrentId] = useState<number>(1);

    // alert 표시 여부
    const [showAlert, setShowAlert] = useState(false);

    // SSE zustand store
    const { connect, disconnect, isConnected, messages: sseMessages } = useSSEStore();

    // OpenAI 클라이언트
    const openai = new OpenAI({
        apiKey: process.env.REACT_APP_OPENAI_API_KEY ?? '',
        dangerouslyAllowBrowser: true,
    });

    // 초기 메시지
    useEffect(() => {
        if (messages.length === 0) {
            const welcomeMessage =
                '안녕하세요 저는 인공지능 라벨링 챗봇입니다.\n' +
                '해당 프로젝트에서 라벨링을 원하는 클래스를 말씀해주세요.\n\n' +
                '예시) person, cup, dog, face 라벨링 해줘.';
            setMessages([
                {
                    id: 1,
                    lastQuery: '',
                    message: [{ role: 'assistant', content: welcomeMessage }],
                },
            ]);
            setCurrentId(1);
        }
    }, [messages]);

    // OpenAI API 호출
    const getBotResponse = async (query: string) => {
        try {
            setLoading(true);

            // placeholder 메시지 추가
            setMessages((prev) =>
                prev.map((item) => {
                    if (item.id === currentId) {
                        return {
                            ...item,
                            lastQuery: query,
                            message: [...item.message, { role: 'assistant', content: '' }],
                        };
                    }
                    return item;
                }),
            );

            const currentConversation = messages.find((item) => item.id === currentId);
            let conversation: { role: string; content: string }[] = currentConversation
                ? currentConversation.message.map((m) => ({ role: m.role, content: m.content }))
                : [];

            const description =
                '배열 형태로 라벨을 담아주고 배열이외에는 아무 메시지도 표시하지마세요.\n\n예) [ "car", "fork" ]';

            conversation.push({
                role: 'user',
                content: `query : ${query}, description : ${description}`,
            });

            const completion = await openai.chat.completions.create({
                model: 'gpt-3.5-turbo',
                messages: conversation,
            });
            const assistantReply = completion.choices[0]?.message?.content || '응답을 받을 수 없습니다.';

            // 배열 여부 판별
            let isResult = true; // 기본값
            try {
                const parsed = JSON.parse(assistantReply);
                if (!Array.isArray(parsed)) {
                    isResult = false;
                }
            } catch (e) {
                isResult = false;
            }
            setAssistantResponse({ content: assistantReply, isResult });
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    };

    // 마지막 placeholder를 실제 응답으로 대체
    const setAssistantResponse = ({ content, isResult }: { content: string; isResult?: boolean }) => {
        setMessages((prev) =>
            prev.map((item) => {
                if (item.id === currentId) {
                    return {
                        ...item,
                        message: [...item.message.slice(0, -1), { role: 'assistant', content, isResult }],
                    };
                }
                return item;
            }),
        );
        setLoading(false);
    };

    // No 버튼 => isResult = false
    const handleNoClick = (messageIndex: number) => {
        setMessages((prev) =>
            prev.map((item) => {
                if (item.id === currentId) {
                    const newMessages = item.message.map((msg, idx) =>
                        idx === messageIndex ? { ...msg, isResult: false } : msg,
                    );
                    return { ...item, message: newMessages };
                }
                return item;
            }),
        );
    };

    // Yes 버튼 클릭 => 서버로 POST + SSE 연결, 완료 후 alert 표시
    const handleYesClick = async (messageIndex: number) => {
        try {
            setLoading(true);

            const currentData = messages.find((m) => m.id === currentId);
            if (!currentData) return;

            const targetMsg = currentData.message[messageIndex];
            if (!targetMsg) return;

            const detectClasses = JSON.parse(targetMsg.content);
            if (Array.isArray(detectClasses)) {
                setShowAlert(true);
                setLoading(false);
                await connect(taskInstance?.id, detectClasses);
            }
        } catch (error) {
            setLoading(false);
            setShowAlert(false);
            toast.error(`[SERVER ERROR] TASK ID: ${taskInstance?.id} 작업이 실패하였습니다.!`, {
                position: 'top-right',
                autoClose: false, // 3초 후 자동 닫힘
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'colored', // 테마 설정
            });
        } finally {
            setLoading(false);
        }
    };

    // 사용자 입력 전송
    const handleSendMessage = async () => {
        if (!chatInput.trim()) return;
        setMessages((prev) =>
            prev.map((item) =>
                item.id === currentId
                    ? {
                          ...item,
                          lastQuery: chatInput,
                          message: [...item.message, { role: 'user', content: chatInput, isResult: false }],
                      }
                    : item,
            ),
        );
        await getBotResponse(chatInput);
        setChatInput('');
    };

    // Alert "확인" 버튼 클릭 → 닫기
    const handleAlertConfirm = () => {
        setShowAlert(false);
        // window.close();
        closeDialog();
    };

    return (
        <>
            <div className='cvat-text-color'>
                <div className='cvat-message-list'>
                    {messages
                        .filter((item) => item.id === currentId)
                        .flatMap((item) => item.message)
                        .map((msg, index, allMessages) => (
                            <div key={index} className='cvat-message-item'>
                                {msg.role === 'assistant' ? (
                                    <>
                                        {loading && index === allMessages.length - 1 ? (
                                            <LoadingOutlined />
                                        ) : (
                                            <RobotOutlined />
                                        )}
                                        <ReactMarkdown>{msg.content}</ReactMarkdown>
                                        {msg?.isResult && (
                                            <div className='result-buttons' style={{ marginTop: '8px' }}>
                                                <Button
                                                    type='primary'
                                                    onClick={() => handleYesClick(index)}
                                                    style={{ marginRight: '8px' }}
                                                >
                                                    Yes
                                                </Button>
                                                <Button type='default' onClick={() => handleNoClick(index)}>
                                                    No
                                                </Button>
                                            </div>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        <UserOutlined />
                                        <ReactMarkdown>{msg.content}</ReactMarkdown>
                                    </>
                                )}
                            </div>
                        ))}
                </div>
            </div>

            <div
                className='cvat-input-container'
                style={{
                    position: 'relative',
                    bottom: -10,
                }}
            >
                <Space.Compact block>
                    <Input.TextArea
                        placeholder='메시지를 입력해주세요'
                        allowClear
                        value={chatInput}
                        className='cvat-height'
                        onChange={(e) => setChatInput(e.target.value)}
                        onPressEnter={(e) => {
                            if (!e.shiftKey) {
                                e.preventDefault();
                                handleSendMessage();
                            }
                        }}
                    />
                    {loading ? (
                        <Button className='cvat-height' onClick={() => setLoading(false)}>
                            Stop
                        </Button>
                    ) : (
                        <Button className='cvat-height' onClick={handleSendMessage}>
                            Send
                        </Button>
                    )}
                </Space.Compact>
            </div>

            {/* 알림(Alert) + 반투명 배경 */}
            {showAlert && (
                <div
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(0,0,0,0.4)', // 반투명 배경
                        zIndex: 9999,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <div style={{ width: '400px' }}>
                        <Alert
                            message={
                                <>
                                    {'작업이 요청되었습니다.'}
                                    <br />
                                    {'완료되면 메시지로 알려드리겠습니다.'}
                                </>
                            }
                            type='success'
                            showIcon
                            closable
                            style={{ marginBottom: '16px' }}
                            description={
                                <Button type='primary' onClick={handleAlertConfirm}>
                                    확인
                                </Button>
                            }
                        />
                    </div>
                </div>
            )}
        </>
    );
}

export default React.memo(ModelAiTalk);
