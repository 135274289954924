import create from 'zustand';
import { toast } from 'react-toastify';

interface SSEState {
    isConnected: boolean;
    messages: string[];
    connect: (taskId: number, detectClasses: any) => Promise<void>; // POST + 스트리밍 시작
    disconnect: () => void; // 스트리밍 중단
}

export const useSSEStore = create<SSEState>((set, get) => {
    let abortController: AbortController | null = null;

    return {
        isConnected: false,
        messages: [],

        connect: async (taskId: number, detectClasses: any) => {
            console.log('작업 테스트');
            if (get().isConnected) return; // 이미 연결 중이면 중단

            try {
                set({ isConnected: true });
                abortController = new AbortController();

                // 1) POST로 요청
                const response = await fetch(`${process.env.REACT_APP_DETECTION_SERVER_URL}/insert`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        taskId: taskId,
                        detectClasses: detectClasses,
                    }),
                    signal: abortController.signal,
                });

                if (!response.ok || !response.body) {
                    console.error('Request failed:', response.status, response.statusText);
                    set({ isConnected: false });
                    return;
                }

                // 2) 스트리밍 바디(ReadableStream) 읽기
                const reader = response.body.getReader();
                const decoder = new TextDecoder('utf-8');
                let contentBuffer = '';

                while (true) {
                    const { value, done } = await reader.read();
                    if (done) {
                        // 스트림 종료 시점
                        console.log('Stream ended.');
                        // === 여기가 스트림이 정상 종료된 시점 ===
                        toast.success(`TASK ID: ${taskId} 작업이 완료되었습니다!`, {
                            position: 'top-right',
                            autoClose: false, // 3초 후 자동 닫힘
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: 'colored', // 테마 설정
                        });
                        break;
                    }

                    // chunk → 문자열 디코딩
                    const chunk = decoder.decode(value, { stream: true });
                    contentBuffer += chunk;
                    console.log('chunk : ', chunk);

                    // SSE-like 형식 파싱 ("\n\n" 구분)
                    let sseEvents = contentBuffer.split('\n\n');
                    contentBuffer = sseEvents.pop() || '';

                    // 각 이벤트 파싱
                    for (const evt of sseEvents) {
                        console.log('evt : ', evt);
                        let lines = evt.split('\n');
                        let eventName = '';
                        let eventData = '';

                        for (const line of lines) {
                            if (line.startsWith('event:')) {
                                eventName = line.slice('event:'.length).trim();
                            } else if (line.startsWith('data:')) {
                                eventData += line.slice('data:'.length).trim() + '\n';
                            }
                        }

                        console.log('SSE Event:', eventName, 'Data:', eventData);
                        set((state) => ({
                            messages: [...state.messages, `[${eventName}] ${eventData.trim()}`],
                        }));

                        if (eventName === 'end') {
                            console.log('END EVENT: stop streaming if needed');
                        }
                    }
                }
            } catch (error) {
                // fetch 에러 혹은 abort
                console.error('SSE fetch error:', error);
                throw error;
            } finally {
                // 요청이 끝나면(정상/에러/abort), 연결 해제
                set({ isConnected: false });
                abortController = null;
            }
        },

        disconnect: () => {
            if (abortController) {
                abortController.abort();
                abortController = null;
            }
            set({ isConnected: false, messages: [] });
        },
    };
});
